header {
  background: linear-gradient(rgb(118, 120, 237) 0%, rgb(61, 52, 139) 100%);
}

header div {
  width: 100%;
  padding: 10px;
  color: white;
  font-family: Calibri;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

div h1 {
  font-size: 20px;
  font-weight: 200;
}

div a {
  text-decoration: none;
  color: whitesmoke;
}

.search {
  display: flex;
}

.search input {
  border: 1px solid #d2cece;
  width: 13em;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: 0 0 0.5em #d2cece;
}

.search button {
  width: 100%;
}

@media only screen and (max-width: 750px) {
  h1 a {
    text-align: center;
  }
  
  .search input {
    width: 100%;
  }
}