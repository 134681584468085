button, .btn {
  background: linear-gradient(rgb(247, 184, 1) 0%, rgb(243, 91, 4) 100%);
  color: white;
  border: 1px solid white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 0.5em #d2cece;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}

button:hover, .btn:hover {
  background: #AC6BBF;
  cursor: pointer;
}


@media only screen and (max-width: 750px) {
  .btn {
    width: 49%;
  }
  
  .search {
    width: 100%;
    margin-top: 10px;
  }
  
}