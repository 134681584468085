.map {
  background: #F7B801;
  height: calc(100vh - 48px);
}

.leaflet-container {
  height: 100%;
}

.leaflet-popup-content {
  width: 100%;
}

.leaflet-popup-content-wrapper {
  min-width: 300px;
}

p {
  font-family: Calibri;
}